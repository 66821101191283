* {
  color: #4a4a4a;
}

.bg-Main {
  background-color: #ff484a;
}

.bg-hoverNav {
  color: #ca4a20;
}

.bg-Bone {
  background-color: #f4f4f4;
}

.bg-Bone2 {
  background-color: #f4f4f4;
}

.bg-navBox {
  background-color: #efefef;
}

.text-Main {
  color: #ff484a;
}

.border-Main {
  border-color: #ff484a;
}

.border-Main2:hover {
  border-color: #ff484a;
}

.border-gary-C {
  border-color: #efefef;
}

.border-gary-C2 {
  border-color: #5f5f5f;
}

.css-1s2u09g-control {
  height: 10px !important;
  background-color: transparent !important;
  border-color: none !important;
  border-radius: none !important;
  border-style: none !important;
  border-width: none !important;
}

.border-y-16 {
  border-top-width: 16px;
  border-bottom-width: 16px;
}

.border-b-16 {
  border-bottom-width: 16px;
}

.letraMain {
  color: #5f5e5e;
}
.text-ssm {
  font-size: 10px;
}

[type='checkbox'],
[type='radio'] {
  width: 20px;
  height: 20px;
  border-radius: 0px;
}

/* seccion de Beneficios*/

.containerBenefits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 75px;
}

.titleBenefits {
  color: #ff484a;
  font-weight: 700;
  font-size: 48px;
  line-height: 58.51px;
}

.parafaphBenefits {
  margin-top: 29px;
  color: #4a4a4a;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 95%;

  /* padding: 0 230px; */
}

.containerItemsBenefits {
  margin-top: 78px;
  width: 100%;
}
ul {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.ul2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cardBenefits {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 277px;
  margin-bottom: 100px;
}

.containerLogo {
  background-color: #ff484a;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardTitle {
  margin: 20px 0;
  color: #ff484a;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.paragraphCard {
  color: #4a4a4a;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

/* control de ofertas y ventas */

.conatinerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 43px;
}

.titleSextionControl {
  margin-top: 66px;
  color: #4a4a4a;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 81px;
  padding: 0 10px;
}

.titleSextionControl span {
  color: #ff484a;
}

.contianerCardsControl {
  width: 100%;
}

.containerCardControlAndSales {
  width: 400px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.imgControlAndSales {
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  margin-bottom: 30px;
}

.paragraphCardControlAndSales {
  color: #4a4a4a;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* nececidades pide y pasa */

.containerCardNeed {
  width: 400px;
  height: 727.723px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 64px;
}

.imgControlNeed {
  width: 400px;
  height: 400px;
  margin-bottom: 41px;
}

.titleCardNeed {
  color: #4a4a4a;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  padding: 0 20px;
}

.paragaphCardNeed {
  color: #4a4a4a;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 25px 23px;
  text-align: justify;
}

/* soonMobile */

.containerSoonMobile {
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding-top: 45px;
  height: 400px;
  padding: 0 20px;
}
.imagePerson1 {
  height: 280px;
  width: 280;
  margin-top: 120px;
}
.contentSoonMobile {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
}
.tileSoon {
  color: #4a4a4a;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* padding-top: 45px; */
}
.paragraphSoon {
  color: #4a4a4a;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 38px;
}

.buttonSoon {
  margin: 35px 0;
  /* margin-top: 30px; */
  border-radius: 30px;
  background: #ff484a;
  width: 225px;
  height: 45px;
  flex-shrink: 0;
  color: #f4f4f4;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  justify-content: center;
}

.imagePerson2 {
  height: 300px;
  width: 280px;
  margin-top: 100px;
}

/* nosotros */

.contentAbout {
  padding: 0 93px;
}

.titleAbout {
  color: #4a4a4a;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 68px;
}
.paragraphAbout {
  color: #4a4a4a;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 43px;
  padding-bottom: 60px;
}

.aboutHeroIT {
  height: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: center;
}

.contentAboutIT {
  width: 50%;
  margin: 0 93px;
}
.titleAboutIT {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.paragraphIT {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* text-align: justify; */
  margin-top: 29px;
}

.btnIT {
  width: 225px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  color: #4a4a4a;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.containerCardAbout {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.cardAboutSpace {
  justify-content: space-between;
  width: 90%;
}

.cardAbout {
  width: 550px;
  margin-bottom: 50px;
}

.titleCardAbout {
  color: #4a4a4a;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 58px 0 20px 0;
}

.paragraphCardAbout {
  color: #4a4a4a;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 26px;
  text-align: justify;
}

.linkCardAbout {
  color: #4a4a4a;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.workUsAbot {
  color: #4a4a4a;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  margin-bottom: 100px;
}
.casaMatrisAbout {
  display: flex;
  justify-content: center;
  height: 150px;
  margin-bottom: 100px;
}
.containerMatris {
  justify-content: space-around;
  width: 90%;
}

.contetMatris {
  display: flex;

  align-items: center;
}

.imgMatris {
  height: 65px;
}

.titleMatris {
  color: #4a4a4a;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.titleMatris span {
  font-weight: 700;
}

.divider {
  background: #c8c8c8;
  width: 1px;
  height: 100px;
}
.divider2 {
  background: #f4f4f4;
  width: 1px;
  height: 40px;
}

.aboutHero {
  height: 509px;
  background-size: contain;
  background-repeat: no-repeat;
}

.contetMatris2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* register */

.containerSection {
  /* background-color: red; */
  height: 100%;
  display: flex;
}
.containerImg {
  width: 50%;
  /* height: 926px; */
  /* overflow: hidden; */
}
.containerForm {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
  padding: 0 45px;
}

.titleForm {
  color: #4a4a4a;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 47px;
}
.titleForm span {
  color: #ff484a;
}
.paragraphForm {
  margin: 20px 0;
  color: #4a4a4a;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.itemNavBar {
  color: #f4f4f4;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.itemNavBar2 {
  padding-top: 5px;
  color: #f4f4f4;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 8px;
}

.desplegable {
  display: flex;
  flex-direction: column;
  background-color: blue;
}

.containerItemNav {
  background-color: white;
}

.subItemNav {
  color: #979797;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subItemText {
  color: #979797;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 20px;
  padding: 10px;
}

.containerItemNavBar {
  box-shadow: none;
}
.containerMenu {
  width: 100%;
}

.containerMenu :hover {
  background-color: #ebebeb;
}

.itemMobile {
  text-decoration: none;
  list-style: none;
}

.itemButton {
  padding-top: 8px;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .imagePerson1 {
    display: none;
  }
  .containerImg {
    display: none;
  }
  .containerForm {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .imagePerson2 {
    display: none;
  }

  .containerBenefits {
    background-color: white;
  }

  /* .containerSoonMobile {
    margin-top: 30px;
    padding: 0 20px;
    background-color: red;
  } */
}

@media screen and (max-width: 390px) {
  .aboutHero {
    height: 150px;
  }
  .aboutHeroIT {
    height: 600px;
    background-size: cover;
    /* background-color: red; */
  }
}

@media screen and (max-width: 768px) {
  .contentAbout {
    padding: 0 20px;
  }
  .titleAbout {
    text-align: center;
  }
  .paragraphAbout {
    text-align: justify;
  }
  .aboutHeroIT {
    height: 340px;
  }
  .contentAboutIT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .titleAboutIT {
    margin-top: 25px;
    font-size: 36px;
    text-align: center;
  }

  .paragraphIT {
    font-size: 15;
    text-align: justify;
  }

  .cardAboutSpace {
    justify-content: center;
  }
  .contetMatris2 {
    display: none;
  }
  .aboutHero {
    height: 309px;
  }
}

.tituloPrincipal {
  color: #4a4a4a;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tituloSecundario {
  color: #4a4a4a;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.textoGeneral {
  color: #4a4a4a;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 767px) {
  .tituloPrincipal {
    font-size: 25px;
  }

  .tituloSecundario {
    font-size: 20px;
  }

  .textoGeneral {
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .tituloPrincipal {
    font-size: 20px;
  }

  .tituloSecundario {
    font-size: 18px;
  }

  .textoGeneral {
    font-size: 14px;
  }
}

.itemNavCustom {
  font-family: montserrat;
  font-size: 16;
  font-weight: 500;
}
